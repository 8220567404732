
import { defineComponent, PropType } from 'vue';
import Transcription from '@/components/print/Transcription.vue';
import ArabicText from '@/components/print/ArabicText.vue';

export default defineComponent({
  components: {
    ArabicText,
    Transcription,
  },
  props: {
    arabicTextInfo: String,
    transcriptionInfo: String,
    arabicVerse: Array as PropType<Array<string>>,
    transcription: Array as PropType<Array<string>>,
    selectedWord: Number,
    makeLinks: Boolean,
    horizontal: Boolean,
  },
  emits: ['wordSelected'],
});
