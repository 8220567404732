import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "py-6 text-center" }
const _hoisted_2 = { class: "inline-block max-w-full text-right" }
const _hoisted_3 = { class: "py-2" }
const _hoisted_4 = { for: "filter" }
const _hoisted_5 = { class: "text-center max-w-full inline-block overflow-x-auto bg-white border-2" }
const _hoisted_6 = { class: "py-3" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "max-w-screen-md mx-auto bg-white py-6 p-3" }
const _hoisted_9 = { class: "py-3 text-center" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_VerseBar = _resolveComponent("VerseBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_VariantsTable = _resolveComponent("VariantsTable")!
  const _component_VerseText = _resolveComponent("VerseText")!
  const _component_VariantsContributors = _resolveComponent("VariantsContributors")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('navigation.variant_readings')
    }, null, 8, ["title"]),
    _createVNode(_component_VerseBar),
    (_ctx.isLoaded(_ctx.variantResults) && _ctx.isLoaded(_ctx.canonical))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_i18n_t, {
            keypath: "variants.explanation",
            tag: "p",
            for: "variants.overview_link",
            class: "text-center showLinks pt-6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: { name: 'VariantReadingsOverview' } }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('variants.overview_link')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('variants.filter_table') + ':'), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "filter",
                  placeholder: "Search terms...",
                  class: "border mx-2",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.search]
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_VariantsTable, {
                  variants: _ctx.variantResults.payload,
                  canonical: _ctx.canonical.payload,
                  searchTerm: _ctx.searchTerm,
                  class: "mx-auto"
                }, null, 8, ["variants", "canonical", "searchTerm"])
              ])
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('variants.click_instruction')), 1)
          ]),
          _createVNode(_component_VerseText, {
            verse: _ctx.variantResults.payload.reference
          }, null, 8, ["verse"]),
          (_ctx.variantResults.payload.commentary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t('variants.verse_commentary')), 1),
                  _createElementVNode("p", {
                    innerHTML: _ctx.variantResults.payload.commentary
                  }, null, 8, _hoisted_10)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isLoaded(_ctx.variantResults) && _ctx.variantResults.payload.citations.length > 0)
            ? (_openBlock(), _createBlock(_component_VariantsContributors, {
                key: 1,
                contributions: _ctx.variantResults.payload.citations
              }, null, 8, ["contributions"]))
            : _createCommentVNode("", true),
          (_ctx.isLoaded(_ctx.variantsWeb))
            ? (_openBlock(), _createBlock(_component_HowToQuote, {
                key: 2,
                citation: _ctx.variantsWeb.payload.how_to_cite
              }, null, 8, ["citation"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}