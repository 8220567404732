import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArabicText = _resolveComponent("ArabicText")!
  const _component_Transcription = _resolveComponent("Transcription")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ArabicText, {
      arabicTextInfo: _ctx.arabicTextInfo,
      arabicVerse: _ctx.arabicVerse,
      selectedWord: _ctx.selectedWord,
      onWordSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('wordSelected', $event))),
      class: "pr-3"
    }, null, 8, ["arabicTextInfo", "arabicVerse", "selectedWord"]),
    _createVNode(_component_Transcription, {
      transcriptionInfo: _ctx.transcriptionInfo,
      transcription: _ctx.transcription,
      selectedWord: _ctx.selectedWord,
      onWordSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('wordSelected', $event))),
      class: "pl-3"
    }, null, 8, ["transcriptionInfo", "transcription", "selectedWord"])
  ], 64))
}