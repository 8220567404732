import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArabicTextAndTranscription = _resolveComponent("ArabicTextAndTranscription")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ArabicTextAndTranscription, {
      transcriptionInfo: _ctx.variantsWeb.payload?.transcription_info,
      arabicTextInfo: _ctx.variantsWeb.payload?.arabic_text_info,
      arabicVerse: _ctx.arabicVerse,
      transcription: _ctx.transcription,
      selectedWord: _ctx.selectedWord,
      onWordSelected: _ctx.setSelectedWord,
      horizontal: true
    }, null, 8, ["transcriptionInfo", "arabicTextInfo", "arabicVerse", "transcription", "selectedWord", "onWordSelected"])
  ]))
}