
import { defineComponent, PropType } from 'vue';
import { VariantsReader } from '@/interfaces/VariantsReader';
import { VariantsWork } from '@/interfaces/VariantsWork';

export default defineComponent({
  props: {
    work: Object as PropType<VariantsWork | undefined>,
    reader: Object as PropType<VariantsReader | undefined>,
  },
});
