
import { contributorsString, VariantsContribution } from '@/interfaces/VariantsContribution';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    contributions: Array as PropType<Array<VariantsContribution>>,
  },
  setup() {
    return {
      contributorsString,
    };
  },
});
