import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "whitespace-normal" }
const _hoisted_2 = {
  key: 0,
  class: "whitespace-normal"
}
const _hoisted_3 = {
  key: 1,
  class: "whitespace-normal"
}
const _hoisted_4 = {
  key: 0,
  class: "whitespace-normal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(`${_ctx.reader ? _ctx.reader.name : ''}${_ctx.work ? _ctx.work.name : ''}`), 1),
    (_ctx.work)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(`${_ctx.$t('variants.death_year')}: ${_ctx.work.death_year}n.Chr./${_ctx.work.death_year_arabic}AH`), 1))
      : _createCommentVNode("", true),
    (_ctx.reader && _ctx.reader.death_year)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(`${_ctx.$t('variants.death_year')}: ${_ctx.reader.death_year}${_ctx.$t('variants.ce')}`) + " ", 1),
          (_ctx.reader.death_year_arabic)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(` /${_ctx.reader.death_year_arabic}${_ctx.$t('variants.ah')}`), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}